<template>
  <v-dialog v-model="dialog" persistent max-width="500">
    <v-card grid-list-md>
      <ModelTitle title="New Attachment" @close="close()" />
      <v-card-text>
        <v-row>
          <v-col sm="12">
            <v-text-field
              :error-messages="nameErrors"
              :hide-details="nameErrors.length === 0"
              v-model="Attachment.name"
              outlined
              dense
              label="Name"
            >
            </v-text-field
          ></v-col>
        </v-row>
        <v-card-text>
          <v-layout align-center justify-center column fill-height>
            <v-card
              outlined
              height="340"
              width="340"
              color="grey lighten-4"
              @click="pickFile"
              hover
            >
              <v-layout align-center justify-center column fill-height>
                <v-flex shrink v-if="!imageUrl">
                  <div class="text-center">
                    <v-icon size="100">mdi-cloud-upload-outline</v-icon>
                    <div>Click here to upload image</div>
                  </div>
                </v-flex>
                <img height="340" width="340" :src="imageUrl" v-else />
              </v-layout>
            </v-card>
          </v-layout>
          <input
            type="file"
            style="display: none"
            ref="image"
            accept="image/*"
            @change="onFilePicked"
          />
        </v-card-text>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="info" text="text" @click="close">Close</v-btn>
        <v-btn color="info" :loading="loading" @click="submit">Upload</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import attachmentService from "../service.js";

const { validationMixin } = require("vuelidate");
const { required } = require("vuelidate/lib/validators");

export default {
  name: "create-attachment",
  props: {
    createDialog: {
      default: false,
    },
    TypeId: {
      default: 2,
    },
    RelationId: {
      type: Number,
    },
  },
  data() {
    return {
      imageName: "",
      imageUrl: "",
      imageFile: "",
      loading: false,
      dialog: false,
      Attachment: {
        name: null,
        base64: null,
      },
    };
  },
  mixins: [validationMixin],
  validations: {
    Attachment: {
      name: {
        required,
      },
    },
  },
  watch: {
    createDialog(val) {
      this.dialog = val;
    },
    imageUrl(val) {
      this.Attachment.base64 = val;
    },
  },
  computed: {
    ...mapGetters("global", ["currentUser"]),
    nameErrors() {
      const errors = [];
      if (!this.$v.Attachment.name.$dirty) return errors;
      if (!this.$v.Attachment.name.required)
        errors.push(this.$t("validations.fieldIsRequired"));

      return errors;
    },
  },
  methods: {
    pickFile() {
      this.$refs.image.click();
    },
    onFilePicked(e) {
      const files = e.target.files;
      if (files[0] !== undefined) {
        this.imageName = files[0].name;
        if (this.imageName.lastIndexOf(".") <= 0) {
          return;
        }
        const fr = new FileReader();
        fr.readAsDataURL(files[0]);
        fr.addEventListener("load", () => {
          this.imageUrl = fr.result;
          this.imageFile = files[0]; // this is an image file that can be sent to server...
        });
      } else {
        this.imageName = "";
        this.imageFile = "";
        this.imageUrl = "";
      }
    },
    close() {
      this.empty();
      this.$emit("close");
    },
    empty() {
      this.Attachment = {
        name: null,
        TypeId: this.TypeId,
        base64: null,
      };
      this.$v.$reset();
    },
    submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.$swal
          .mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
          })
          .fire({
            icon: "error",
            title: this.$t("toastMessage.text.invalidFields"),
          });
      } else {
        this.Attachment.TypeId = this.TypeId;
        this.Attachment.RelationId = this.RelationId;
        // console.log('RelationId', this.Attachment)
        this.loading = true;
        return attachmentService
          .create(this.Attachment)
          .then((result) => {
            if (result.status) {
              this.$swal
                .mixin({
                  toast: true,
                  position: "top-end",
                  showConfirmButton: false,
                  timer: 3000,
                })
                .fire({
                  icon: "success",
                  title: "Image is Uploaded!",
                });
              this.loading = false;
              this.$emit("submit");
              this.close();
            }
          })
          .catch((err) => {
            // console.log("image", err);
            this.loading = false;
            this.$swal
              .mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
              })
              .fire({
                icon: "error",
                title: err.data.message,
              });
          });
      }
    },
  },
};
</script>

<style scoped></style>
